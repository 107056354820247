'use client'

import { atom, useAtom } from 'jotai'
import { useCachedMyProfile } from './useMyProfile'
import { useCallback, useMemo } from 'react'
import { usePathname, useRouter } from 'next/navigation'
import { useBreakpoint } from './useBreakPoint'
import { cls, getLocalStorage, getSessionStorage, isLocalDev, isProduction, setLocalStorage, whisper } from '@/utils'
import { useCachedSwitches } from './useSwitches'
import { CACHE_KEY_ONBOARDING_SURVEY_TIME } from '@/constants'
import useAmplitude from './useAmplitude'
import { sendGTMEvent } from '@next/third-parties/google'

export const showSurveyAtom = atom<boolean>(false)
export const surveyClosingAtom = atom<boolean>(false)

const surveyStartTimeMilliSeconds = isProduction ? 18 * 60 * 60 * 1000 : 5 * 60 * 1000
const surveyEndTimeMilliSeconds = isLocalDev ? Number.MAX_SAFE_INTEGER : 48 * 60 * 60 * 1000

const showCloseAnimation = false

export default function useSurvey() {
  const [showSurvey, setShowSurvey] = useAtom(showSurveyAtom)
  const [surveyClosing, setSurveyClosing] = useAtom(surveyClosingAtom)
  const { isBelowMd } = useBreakpoint('md')
  const { track } = useAmplitude()

  const pathname = usePathname()
  const router = useRouter()

  const dialogClassName = useMemo(
    () =>
      cls(
        surveyClosing
          ? '-translate-x-1/2 md:translate-x-full translate-y-full'
          : '-translate-x-1/2 -translate-y-1/2 scale-100',
      ),
    [surveyClosing],
  )

  const { data: switches, isValidating: switchesLoading } = useCachedSwitches()
  const { data: profile } = useCachedMyProfile()

  const isPlayground = pathname === '/playground/survey'

  const visible =
    (isPlayground || (profile && !profile?.survey_completed)) && showSurvey && !!switches?.onboarding_survey_1028

  const openSurvey = useCallback(() => {
    setShowSurvey(true)
    setLocalStorage(CACHE_KEY_ONBOARDING_SURVEY_TIME, new Date().toISOString())
  }, [setShowSurvey])

  const tryOpenSurvey = useCallback(() => {
    // if (isLocalDev) {
    //   openSurvey()
    //   return
    // }

    const lastOpenTime = getLocalStorage<string>(CACHE_KEY_ONBOARDING_SURVEY_TIME)
    if (lastOpenTime) {
      return
    }

    // only open survey for new user. create_time between 18 hours to 48 hours
    const createTime = profile?.create_time
    if (createTime && profile && !profile?.survey_completed) {
      const now = new Date().getTime()
      const createTimestamp = new Date(createTime).getTime()

      const debugMode = getSessionStorage('debug.onboarding_survey') === true

      if (
        debugMode ||
        (now - createTimestamp >= surveyStartTimeMilliSeconds && now - createTimestamp <= surveyEndTimeMilliSeconds)
      ) {
        track('click:creations:generate-after-signup-d1')
        sendGTMEvent({
          event: 'generate_after_signup_d1',
          category: 'signup',
          label: 'generate_after_signup_d1',
        })
        openSurvey()
      }
    }
  }, [openSurvey, profile, track])

  const closeSurvey = useCallback(() => {
    if (surveyClosing) {
      return
    }
    setSurveyClosing(true)

    if (showCloseAnimation) {
      // setShowSurvey(false)
      const dialog: HTMLDivElement | null = document.querySelector('#persona-survey')
      const navLinkCreate: HTMLAnchorElement | null = document.querySelector('#nav-link-create')

      // move survey dialog to the position of create button, with animation
      if (dialog && navLinkCreate) {
        const dialogRect = dialog.getBoundingClientRect()
        const buttonRect = navLinkCreate.getBoundingClientRect()

        const scaleX = buttonRect.width / dialogRect.width
        const scaleY = buttonRect.height / dialogRect.height

        const transformStart = ['translateX(-50%)', 'translateY(-50%)', 'scaleX(1)', 'scaleY(1)'].join(' ')

        const transformEnd = [
          isBelowMd ? 'translateX(-50%)' : 'translateX(-100%)',
          isBelowMd ? 'translateY(100%)' : `translateY(calc(${buttonRect.top}px - 100%))`,
          `scaleX(${scaleX})`,
          `scaleY(${scaleY})`,
        ].join(' ')

        const keyFrames = [{ transform: transformStart }, { transform: transformEnd }]

        dialog.animate(keyFrames, {
          duration: 400,
          easing: 'ease-in',
          iterations: 1,
        })
      }
    }

    setTimeout(() => {
      setShowSurvey(false)
      setSurveyClosing(false)
      // router.push('/')
      router.push('/creations')
    }, 300)
  }, [setShowSurvey, setSurveyClosing, router, isBelowMd, surveyClosing])

  return {
    dialogClassName,
    showSurvey: visible,
    tryOpenSurvey,
    openSurvey,
    closeSurvey,
    surveyClosing,
  }
}

import SelectInput from '@/components/select-input'
import { useFieldArray, type UseFormReturn } from 'react-hook-form'
import { ReactElement, useCallback, useEffect, useRef } from 'react'
import Button from '../button'
import IconPlusSmall from '@haiper/icons-svg/icons/outline/plus-small.svg'
import { cls } from '@/utils'
import { SOCIAL_ENTRIES } from '@/constants'
import IconClose from '@haiper/icons-svg/icons/outline/crossed-small.svg'

export interface SocialAccountFormProps {
  title?: ReactElement
  form: UseFormReturn<any>
  inputClassName?: string
  deleteButtonClassName?: string
  fieldName?: string
}

interface Account {
  value: string
  type: string
}

export const socialMediaOptions = SOCIAL_ENTRIES.map((entry) => ({
  value: entry.name,
  label: entry.label,
}))

export default function SocialAccountForm({
  title,
  form,
  fieldName = 'social_accounts',
  deleteButtonClassName,
  inputClassName,
}: SocialAccountFormProps) {
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: fieldName,
  })

  const initedRef = useRef(false)

  const handleAdd = useCallback(
    (e: any) => {
      e?.preventDefault?.()
      const accounts: Account[] = form.getValues(fieldName)
      const unusedType = socialMediaOptions.find(
        (item) => !accounts.some((account) => account.type === item.value),
      )?.value
      append({ value: '', type: unusedType })
    },
    [append, form, fieldName],
  )

  useEffect(() => {
    if (fields.length === 0 && !initedRef.current) {
      append({ value: '', type: 'x' })
      initedRef.current = true
    }
  }, [fields, append])

  return (
    <div className='flex flex-col w-full gap-2' aria-label='social accounts'>
      <span className='text-heading-md font-bold mb-2'>{title}</span>
      {fields.map((field, index) => {
        const inputProps = form.register(`${fieldName}.${index}.value`)
        const selectProps = form.register(`${fieldName}.${index}.type`)

        return (
          <div key={field.id} className='relative'>
            <div className='w-full items-center gap-2 flex'>
              <SelectInput
                key={field.id}
                className={cls(inputClassName, 'gap-4')}
                leadignSelectOptions={socialMediaOptions}
                // inputProps={{...inputProps, placeholder: 'https://...'}}
                inputProps={{
                  ...inputProps,
                  placeholder: 'Account URL',
                  autoComplete: 'off',
                  className: 'pr-4',
                }}
                selectProps={selectProps}
              />
              <Button
                variant='transparent'
                className={cls('w-10 px-0 absolute right-0', deleteButtonClassName)}
                onClick={() => remove(index)}
              >
                <IconClose className='text-icon-subdued' />
              </Button>
            </div>
            {(form.formState.errors[fieldName] as any)?.[index]?.value && (
              <span className='text-text-critical mt-2'>
                {(form.formState.errors[fieldName] as any)?.[index]?.value?.message?.toString() ?? ''}
              </span>
            )}
          </div>
        )
      })}
      <Button
        variant='transparent'
        className={cls(
          'bg-border hover:bg-border-hover rounded-md py-2 px-3 justify-center items-center mt-4',
          fields.length >= socialMediaOptions.length && 'hidden',
        )}
        onClick={handleAdd}
      >
        <div className='flex gap-1 items-center'>
          <IconPlusSmall className='size-6 text-icon' />
          <span className='px-1'>Add an account</span>
        </div>
      </Button>
      {!!form.formState.errors[fieldName] && (
        <span className='text-text-critical text-body-sm mt-2'>
          {form.formState.errors[fieldName]?.message?.toString() ?? ''}
        </span>
      )}
    </div>
  )
}
